import styled from "@emotion/styled";

export const EditorStyled = styled.div`
>div{
  min-height:100px!important;
}
[data-slate-placeholder="true"]{
  color: #2B2B2B;
  opacity:1!important;
};

`