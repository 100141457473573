const breakpoints ={
    xxs: 0,
    xss: "428px",
    xs: "480px",
    sm: '576px',
    md: '768px',
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    xxxl: "1600px"
};

export default breakpoints;