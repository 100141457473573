const initialState = {
  logo: '',
  greeting: [
    {
      type: "paragaph",
      children: [{ text: "" }]
    }
  ],
  companyName: [
    {
      type: "paragaph",
      children: [{ text: "" }]
    }
  ],
  companyEmail: [
    {
      type: "paragaph",
      children: [{ text: "" }]
    }
  ],
  salutations: [
    {
      type: "paragaph",
      children: [{ text: "" }]
    }
  ],
  sender: [
    {
      type: "paragaph",
      children: [{ text: "" }]
    }
  ],
  url: [
    {
      type: "paragaph",
      children: [{ text: "" }]
    }
  ]
}

export default initialState
